<template>
  <div>

    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <b-card>
        <b-row>
          <b-col md="6">
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Mobile"
              rules="required"
            >
              <b-form-group
                label="Enter Mobile Number to search for related orders"
                label-for="Mobile"
              >
                <b-form-input
                  id="Mobile"
                  v-model="orderForm.mobile"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Mobile"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!loader"
              variant="primary"
              class="mr-1"
              :disabled="orderForm.mobile==''"
              @click="getOrderDetails()"
            >
              Search
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>

          </b-col>
        </b-row>
      </b-card>
      <b-row>
        <b-col
          v-for="(order , index) in orderData"
          :key="index"
          cols="3"
        >
          <b-card>
            <div class="text-center">
              <b-img
                width="100"
                :src="order.merchant_logo"
              />
            </div>
            <ul>

              <li>
                Offer Name: {{ order.offer_name }}
              </li>
              <li>
                Date: {{ order.created_at | formatDate }}
              </li>

              <li>
                Coupons:
                <ul
                  v-for="(singlecoupon , index) in order.coupon"
                  :key="index"
                >
                  <li>
                    Purchase Id:  {{ singlecoupon.purchase_id }}

                  </li>
                  <li>
                    Voucher Sn:  {{ replaceNo(singlecoupon.voucher_sn) }}

                  </li>
                  <li>
                    Expire At: {{ singlecoupon.expire_date | formatDate }}
                  </li>
                  <li>
                    Status: {{ singlecoupon.coupon_status.pur_status_name }}
                  </li>
                  <hr>
                </ul>
              </li>
            </ul>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>

  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'

export default {
  data() {
    return {
      loader: false,
      required,
      orderData: [],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const orderForm = ref({
      mobile: '',

    })
    return {
      getValidationState,
      orderForm,
    }
  },
  mounted() {
  },
  methods: {
    replaceNo(word) {
      const masked = word.substring(0, word.length - 4).replace(/[a-z\d]/gi, '*')
    + word.substring(word.length - 4, word.length)
      return masked
    },

    getOrderDetails() {
      this.loader = true
      axios.get(`order/details?mobile=${this.orderForm.mobile}`).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.loader = false
          this.orderData = res.data.data
        }
      })
    },

  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
